<style lang="scss">
#debug {
  display: none;
}
#frontend {
}
</style>

<template>
  <!--
  <HeaderIntroBoxBrands :style="{ 'background-image': `url(${require('@/assets/img/marketplaces/marketplaces-startbild-v2.jpg')})` }"  headerImageText="WILLHABEN">
  -->
  <SocialMediaIconBox />
  <BrandsBox
    :brandImgLogo="require('@/assets/img/marken/brands/styria-digital-development.svg')"
    brandLogoClass="styria-marken-logos styria-digital-development-logo"
    brandAltText="Styria Digital Development"
    brandHeadline="Styria Digital Development"
    :brandLongText="$t('styria-digital-development.txt')"
    brandGruendung="2019"

  />
  <MarkenBoxen />
  <Footer />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import HeaderIntroBoxBrands from '../../../components/Frontend/Brands/HeaderIntroBoxBrands.vue';
import BrandsBox from '../../../components/Frontend/Brands/BrandsBox.vue';
import MarkenBoxen from '../../../components/Frontend/MarkenBoxen.vue';
import Footer from '../../../components/Frontend/Footer.vue';

export default defineComponent({
  meta: {
    de: {
      title: 'Service Partner | Styria Media Groupp',
      keywords: ['service partner', 'marketplaces' , 'brands' , 'digital' , 'styria' ,'cope'],
      description: 'Die Services der STYRIA: Druck, IT, Agenturen, Kundenmanagement, Logistik, Rechnungswesen.',
      image: require('@/assets/img/og/brands.png'),
      
    },
    en: {
      title: 'Service partner | Stryria Media Group',
      keywords: ['service partner', 'marketplaces' , 'brands' , 'digital' , 'styria' ,'cope'],
      description: "STYRIA's services: printing, IT, agencies, customer management, logistics, accounting.",
      image: require('@/assets/img/og/brands.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    //HeaderIntroBoxBrands,
    MarkenBoxen,
    BrandsBox,
    Footer,
  },
  setup() {


    return {
     
    };
  },
});
</script>
